.event-layout {
  height: 100%;
}

.ant-layout {
  aside.ant-layout-sider,
  .ant-menu {
    background-color: #1f1f1f;
  }
  .ant-layout-sider-trigger {
    background-color: #262626;
  }
}

.site-content {
  padding: 16px;
  overflow-y: auto;
  scrollbar-color: transparent #4a4a4a;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
  }
}

@primary-color: #1DA57A;