.auth-layout {
  height: 100vh;

  background: radial-gradient(ellipse at top, #1c1c1c, transparent),
    radial-gradient(ellipse at bottom, #1d1d1d, transparent);
  background-color: #222;
  background-position: center, center;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  .form-container {
    // background-color: #142550;
    border-radius: 1rem;
    padding: 2rem;
    max-width: 560px;
    width: 90vw;
  }
}

@primary-color: #1DA57A;