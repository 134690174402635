.portal-layout.ant-layout {
  height: 100vh;
  overflow: auto;

  .portal-header {
    background-color: #1f1f1f;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .brand-logo {
      height: 40px;
    }

    .ant-dropdown-trigger {
      color: white;
    }
  }

  .portal-content {
    // background-color: #16181D;
  }
}

@primary-color: #1DA57A;